import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Comments from '../components/comments'
import Layout from '../components/Layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import useWindowSize from '../utils/useWindowSize'
import { css } from '@emotion/react'
import SEO from '../components/seo'
import PostInfo from '../components/PostInfo'
import PostNav from '../components/PostNav'

const PhotoPostTemplate = ({ data: { mdx: post }, pageContext }) => {
  const { width, height } = useWindowSize()
  const windowAspectRatio = width / height

  const params = {
    autoPlay: false,
    autoFocus: true,
    dynamicHeight: true,
    emulateTouch: true,
    showIndicators: post.frontmatter.photos.length > 1,
    showStatus: false,
    showThumbs: false,
    useKeyboardArrows: true,
  }
  return (
    <Layout
      cover={
        <Carousel
          {...params}
          css={css`
            margin-bottom: 0.25em;
          `}
        >
          {post.frontmatter.photos.map((photo, i) => {
            if (!photo) return
            const aspectRatio = photo.childImageSharp.fluid.aspectRatio
            const imgStyle = {
              position: 'absolute',
              height: '100%',
              maxWidth: `calc(100vh * ${aspectRatio})`,
              overflow: 'hidden',
              left:
                windowAspectRatio > aspectRatio
                  ? `calc(( ${width}px - 100vh * ${aspectRatio} ) / 2)`
                  : 0,
            }
            return (
              <div
                key={i}
                style={{
                  maxHeight: `100vh`,
                  backgroundImage: `url(${photo.childImageSharp.fluid.base64})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                <GatsbyImage
                  alt="Cover image"
                  className="photo-cover-image"
                  image={photo.childImageSharp.gatsbyImageData}
                  style={{ maxHeight: '100vh' }}
                  imgStyle={imgStyle}
                  placeholderstyle={imgStyle}
                />
              </div>
            )
          })}
        </Carousel>
      }
    >
      <SEO title={post.frontmatter.date} description={post.excerpt} />
      <PostInfo post={post} />
      <MDXRenderer>{post.body}</MDXRenderer>
      <PostNav pageContext={pageContext} />
      <Comments slug={post.fields.slug} status={post.frontmatter.comments} />
    </Layout>
  )
}

export default PhotoPostTemplate

export const pageQuery = graphql`
  query PhotoPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM Do, YYYY")
        tags
        location
        photos {
          childImageSharp {
            fluid {
              aspectRatio
              base64
            }
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        comments
      }
      fields {
        slug
        tagSlugs
      }
    }
  }
`
