import React from 'react'

const useWindowSize = () => {
  const [width, setWidth] = React.useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  )
  const [height, setHeight] = React.useState(
    typeof window !== 'undefined' ? window.innerHeight : 0
  )

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(typeof window !== 'undefined' ? window.innerWidth : 0)
      setHeight(typeof window !== 'undefined' ? window.innerHeight : 0)
    }
    typeof window !== 'undefined' &&
      window.addEventListener('resize', handleResize)
    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', handleResize)
    }
  }, [])
  return { width, height }
}

export default useWindowSize
